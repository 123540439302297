export default {
  hideMessageTimeout: 2000,
  objectTypes: ["text","richtext","image","croppedimage","gallery","datepicker","dropdown", "boolean", "files", "content", "number", "time"],
  contentLanguages: ["Nl","Fr","En"],
  languages: ["Nl","Fr", "En"],
  logoPos: "logo-pos.png",
  logoNeg: "logo.png",
  websiteName: "Krist Demeyere",
  primaryColor: "#1F5BD0",
  secondaryColor: "#504D3E",
  mailto: "soren@streamliners.be",
  apiUrl: "https://cms.kristdemeyere.be",
}