<template>
  <div>
    <backButton />
    <template v-if="loading && error.length == 0">
      <preloader></preloader>
    </template>
    <template v-else>
      <v-layout row>
       <v-flex xs12>
         <v-form ref="form" lazy-validation>
           <h3 class="mb-1">{{ $t('General') }}</h3>

           <v-card class="mb-4">
               <v-card-text>
                 <v-text-field
                  :label="$t('Email')"
                  v-model="user.Email"
                  :rules="[(v) => !!v || $t('Email is required'),(v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || $t('Email must be valid')]"
                  required
                  validate-on-blur
                  ></v-text-field>

                  <v-text-field
                   :label="$t('First name')"
                   v-model="user.FirstName"
                   required
                   :rules="[(v) => !!v || $t('First name is required'),(v) => v.length >= 2 || $t('Minimum required characters is') + ' 2']"
                   validate-on-blur
                   ></v-text-field>

                   <v-text-field
                    :label="$t('Last name')"
                    v-model="user.LastName"
                    required
                    :rules="[(v) => !!v || $t('Last name is required'),(v) => v.length >= 2 || $t('Minimum required characters is') + ' 2']"
                    validate-on-blur
                    ></v-text-field>

                <v-switch v-bind:label="$t('Administrator')" v-model="user.Administrator"></v-switch>

                <v-select
                  v-bind:items="languages"
                  :label="$t('Select a language')"
                  item-value="Language"
                  item-text="Language"
                  v-model="user.Language"
                  required
                  :rules="[(v) => !!v || $t('You must select an item')]"
                  validate-on-blur
                ></v-select>

                <v-btn class="mb-3 ml-0 edit-page-action-button" color="blue" :loading="resendingMail" dark v-on:click="sendWelcomeEmail()">
                 {{ $t('Resend welcome email') }}
                 <v-icon right>email</v-icon>
                </v-btn>
              </v-card-text>

              <v-alert type="success" v-if="welcomeMailSuccess.length > 0" class="mt-3" icon="check" :value="true">
                {{ welcomeMailSuccess }}
              </v-alert>
           </v-card>

           <h3 class="mb-1">{{ $t('Websites') }}</h3>
           <v-card>
               <v-card-text>
                 <template v-for="(website, index) in user.Websites">
                   <v-layout :key=index row wrap>
                      <v-flex xs9 lg11>
                        <h3 class="mt-3">{{ getWebsiteNameFromId(website.$oid) }}</h3>
                      </v-flex>

                      <v-flex xs3 lg1 class="text-right">
                        <v-btn icon color="red" v-on:click="removeUserWebsite(index)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                 </template>

                 <v-select
                   v-if="getWebsitesNotAssigned().length > 0"
                   v-bind:items="getWebsitesNotAssigned()"
                   :label="$t('Select a website')"
                   item-value="_id.$oid"
                   item-text="Name"
                   v-model="websiteToAdd"
                   single-line
                   class="add-item-select mr-3"
                 ></v-select>

                 <v-btn color="green"
                 class="mt-3 edit-page-action-button dropdown-action-button"
                 dark
                 v-on:click="addUserWebsite()"
                 v-if="getWebsitesNotAssigned().length > 0"
                 >
                 {{ $t("Add website") }}
                  <v-icon>add</v-icon>
                 </v-btn>
               </v-card-text>
           </v-card>
        </v-form>
       </v-flex>
      </v-layout>

      <cancelConfirmButtons :confirmFunction="saveUser" :loading="saving" :confirmLabel="$t('Save')" />
    </template>

    <v-alert v-if="error.length > 0" color="error" icon="warning" :value="true">
        {{ error }}
    </v-alert>
  </div>
</template>

<script>
import request from '../../request'
import config from '../../config'
import Vue from 'vue'

export default {
  data () {
    return {
      error: "",
      welcomeMailSuccess: "",
      user: {
              "Email" : "",
              "Administrator" : false,
              "Language" : "",
              "FirstName":"",
              "LastName":"",
              "Websites" : [ ]
          },
      languages: config.languages,
      websites: null,
      websiteToAdd:null,
      loading:false,
      resendingMail:false,
      saving:false
    }
  },
  created () {
    this.getUser(this.$route.params.id)
    this.getWebsites()
  },
  methods: {
    getUser(id){
      if(id){
        this.loading = true

        request.get("/user/"+id, null, (res) => {
          this.loading = false

          if (res.success) {
            this.user = res.data;
          }else{
            this.error = res.message
          }
        })
      }
    },
    saveUser(){
      if (this.$refs.form.validate()) {
        this.saving = true

        request.put("/user", this.user, (res) => {
          this.saving = false

          if (res.success) {
            this.$router.go(-1)
          }else{
            this.error = res.message
          }
        })
      }else{
        this.error = Vue.i18n.translate('Please make sure all fields have a correct value')
      }
    },
    addUserWebsite(){
      if(this.websiteToAdd){
        this.user.Websites.push({
              $oid : this.websiteToAdd,
        })
      }
    },
    removeUserWebsite(index){
        this.user.Websites.splice(index,1)
    },
    getWebsites(){
      request.get("/websites", null, (res) => {
        if (res.success) {
          this.websites = res.data;
        }else{
          this.error = res.message
        }
      })
    },
    getWebsiteNameFromId(id){
      if(this.websites){
        for (var i = 0; i < this.websites.length; i++) {
            if(this.websites[i]._id.$oid == id){
              return this.websites[i].Name
            }
        }
      }
      return ""
    },
    getWebsitesNotAssigned(){
      var websites = []

      if(this.websites && this.user){
          for (var i = 0; i < this.websites.length; i++) {
            var websiteAssignedToUser = false

            for (var ii = 0; ii < this.user.Websites.length; ii++) {
                if(this.websites[i]._id.$oid == this.user.Websites[ii].$oid){
                    websiteAssignedToUser = true
                }
            }

            if(!websiteAssignedToUser){
              websites[websites.length] = this.websites[i]
            }
          }
      }

      return websites
    },
    sendWelcomeEmail(){
      this.resendingMail = true

      request.post("/sendwelcomeemail/"+this.$route.params.id, null, (res) => {
        this.resendingMail = false

        if (res.success) {
          this.welcomeMailSuccess = res.message

          setTimeout(()=>{
             this.welcomeMailSuccess = ""
          }, config.hideMessageTimeout);
        }else{
          this.error = res.message
        }
      })
    },
  }
}
</script>
