import axios from 'axios'
import Vue from 'vue'
import helpers from './helpers'

const serverUrl = helpers.getServerUrl();

export default {
  //create item
  post (url, parameters, callback) {
    axios.defaults.headers.common['Language'] = Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token
    axios.post(
      serverUrl + url,
      parameters
    )
    .then(function (response) {
      callback(successResponseToObject(response))
    })
    .catch(function (errorResponse) {
      callback(errorResponseToObject(errorResponse))
    });
  },

  //get item
  get (url, parameters, callback) {
    axios.defaults.headers.common['Language'] =  Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token
    axios.get(
      serverUrl + url,
      parameters
    )
    .then(function (response) {
      if(callback){
        callback(successResponseToObject(response))
      }
    })
    .catch(function (errorResponse) {
      if(callback){
        callback(errorResponseToObject(errorResponse))
      }
    });
  },

  //multiple get requests
  getAll(urls, parameters, callback) {
    var requests = []
    for (var i = 0; i < urls.length; i++) {
        requests.push(axios.get(
          serverUrl + urls[i],
          parameters
        ))
    }
    axios.defaults.headers.common['Language'] =  Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token
    axios.all(
      requests
    )
    .then(axios.spread((...responses) => {
      var objectResponses = []
      for (var i = 0; i < responses.length; i++) {
        objectResponses.push(successResponseToObject(responses[i]))
      }
      callback(objectResponses)
    }))
    .catch(function (errorResponse) {
      callback(errorResponseToObject(errorResponse))
    });
  },

  //download item
  download(url, parameters, filename, callback){
    axios.defaults.headers.common['Language'] =  Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token

    axios.get(
      serverUrl + url,
      parameters
    )
    .then(function (response) {
      let url = window.URL.createObjectURL(new Blob([response.data]));

      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      callback(successResponseToObject(true))
    })
    .catch(function (errorResponse) {
      callback(errorResponseToObject(errorResponse))
    });
  },

  //update item
  put (url, parameters, callback) {
    axios.defaults.headers.common['Language'] = Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token
    axios.put(
      serverUrl + url,
      parameters
    )
    .then(function (response) {
      callback(successResponseToObject(response))
    })
    .catch(function (errorResponse) {
      callback(errorResponseToObject(errorResponse))
    });
  },

  //update item property
  patch (url, parameters, callback) {
    axios.defaults.headers.common['Language'] =  Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token
    axios.patch(
      serverUrl + url,
      parameters
    )
    .then(function (response) {
      callback(successResponseToObject(response))
    })
    .catch(function (errorResponse) {
      callback(errorResponseToObject(errorResponse))
    });
  },

  // delete item
  delete (url, parameters, callback) {
    axios.defaults.headers.common['Language'] =  Vue.i18n.locale()
    axios.defaults.headers.common['Authorization'] = localStorage.user == null ? "" : JSON.parse(localStorage.user).Token
    axios.delete(
      serverUrl + url,
      parameters
    )
    .then(function (response) {
      callback(successResponseToObject(response))
    })
    .catch(function (errorResponse) {
      callback(errorResponseToObject(errorResponse))
    });
  }
}

function successResponseToObject(response){
  let data = response.data
  data["success"] = true

  return data
}

function errorResponseToObject(errorResponse){
  if(errorResponse.response == undefined){
    return {message:"Oops, something went wrong. Please contact the system administrator.",success:false}
  }

  let errorTitle = errorResponse.response.data.title
  let error = {message:errorTitle,success:false}

  return error
}
