<template>
  <span>
    <backButton class="mt-2" />

    <v-card class="px-8 py-4">
      <languageSelector :addContainer="true"/>

     <br/>

     <v-form @submit.prevent="sendResetLink" ref="form" lazy-validation>
       <v-text-field
         :label="$t('Email')"
         v-model="email"
         required
         validate-on-blur
         :rules="[(v) => !!v || $t('Email is required'),(v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || $t('Email must be valid')]"
       ></v-text-field>

       <div class="text-sm-right">
         <v-btn class="ml-0 mr-0" type="submit" :loading="loading" color="primary">{{$t('Send reset link')}}</v-btn>
       </div>

       <v-alert class="mt-3" v-if="error.length > 0" color="error" icon="warning" :value="true">
           {{ error }}
       </v-alert>

       <v-alert type="success" v-if="success.length > 0" class="mt-3" icon="check" :value="true">
         {{ success }}
       </v-alert>
     </v-form>
    </v-card>
  </span>
</template>

<script>
  import request from '../../request'

  export default {
    data () {
      return {
        error: '',
        success:'',
        email: '',
        loading: false
      }
    },
    methods: {
      sendResetLink () {
        this.error = ""
        this.success = ""

        if (this.$refs.form.validate()) {
          this.loading = true

          request.post("/sendresetlink", this.email, (res) => {
            this.loading = false

            if (res.success) {
              this.success = res.message
            }else{
              this.error = res.message
            }
          })
        }
      }
    }
  }
</script>

<style>
</style>
